import * as ApiActions from './api/ApiActions'
import { ApiUrls } from '../../utils/ApiClient'

export const CHANGE_TEXT = Symbol('CHANGE_TEXT');
export const SUMMARIZATION_REQUEST = Symbol('SUMMARIZATION_REQUEST');
export const SUMMARIZATION_SUCCESS = Symbol('SUMMARIZATION_SUCCESS');
export const SUMMARIZATION_FAILURE = Symbol('SUMMARIZATION_FAILURE');

export const onChangeText = (name, value) => {
  return {
    type: CHANGE_TEXT,
    name: name,
    value, value
  }
}

export const summarization = (requestUrl, document) => {
  const url = ApiUrls.SUMMARIZATION
  const data = {
    url:  requestUrl,
    document: document
  }
  const types = {
    request: SUMMARIZATION_REQUEST,
    success: SUMMARIZATION_SUCCESS,
    failure: SUMMARIZATION_FAILURE,
  }
  return ApiActions.get(url, data, types)
}

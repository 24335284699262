import * as LocationActions from '../actions/LocationActions';

const initialState = {
  list: []
};

const LocationReducer = (state = initialState, action) => {

  switch (action.type) {
    case LocationActions.SEARCH_LOCATION_LIST_REQUEST: {
      return {
        ...state,
        http: {
          isFetching: true,
          response: {},
          error: {},
          lastUpdated: null
        },
      }
    }
    case LocationActions.SEARCH_LOCATION_LIST_SUCCESS: {
      const { list } = action.response
      return {
        ...state,
        list: list,
        http: {
          isFetching: false,
          response: action.response,
          error: {},
          lastUpdated: action.receivedAt
        }
      }
    }
    case LocationActions.SEARCH_LOCATION_LIST_FAILURE: {
      return {
        ...state,
        http: {
          isFetching: false,
          response: {},
          error: action.response,
          lastUpdated: action.receivedAt
        }
      }
    }
    default:
      return state;
  }
};

export default LocationReducer;
import * as UIActions from '../actions/UIActions';

const initialState = {
  DrawerNav: {
    open: false
  },
  SignInDialog: {
    open: false
  },
  LinkCardDialog: {
    open: false
  }
};

const UIReducer = (state = initialState, action) => {

  switch (action.type) {
    case UIActions.OPEN_DIALOG: {
      let next = { ...state }
      next[[action.dialogName]]["open"] = true
      return next;
    }
    case UIActions.CLOSE_DIALOG:{
      let next = { ...state }
      next[[action.dialogName]]["open"] = false
      return next;
    }
    default:
      return state;
  }
};

export default UIReducer;
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class DataBoundary extends React.Component {
  componentDidMount() {
  }

  render() {
    return this.props.children
  }
}

const mapState = (state, ownProps) => {
  return {
  }
};

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({
    }, dispatch),
  };
}

export default connect(mapState, mapDispatch)(DataBoundary);

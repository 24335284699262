export function fetchNewJson(jsonText) {
  const originJson = JSON.parse(jsonText)
  return {
    type: 'FETCH_NEW_JSON',
    payload: {
      originJson: originJson
    }
  }
}

export function createNewText() {
  return {
    type: 'CREATE_NEW_JSON',
    payload: {}
  }
}

export function updateJsonObject(originJson) {
  return {
    type: 'UPDATE_JSON',
    payload: {
      originJson: originJson,
    }
  }
}

export function saveJsonObject(originJson) {
  return {
    type: 'SAVE_JSON',
    payload: {
      originJson: originJson,
    }
  }
}

export function saveJsonObjectInJsonArray(originJson, index, rowJson) {
  originJson[index] = rowJson
  return {
    type: 'SAVE_JSON',
    payload: {
      originJson: originJson,
    }
  }
}

export function addJsonObject(originJson, rowJson) {
  originJson.push(rowJson)
  return {
    type: 'ADD_JSON',
    payload: {
      originJson: originJson,
    }
  }
}

export function selectJsonObjectInJsonArray(originJson, index) {
  return {
    type: 'SELECT_ROW_JSON',
    payload: {
      editJson: originJson[index],
      selectedRowNo: index,
    }
  }
}

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import UIReducer from "./UIReducer";
import NotificationReducer from "./NotificationReducer";
import TargetJsonReducer from "./TargetJsonReducer";
import EditJsonReducer from "./EditJsonReducer";
import ImageDiffReducer from "./ImageDiffReducer";
import LocationReducer from "./LocationReducer";
import SummarizationReducer from "./SummarizationReducer";

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  UIReducer,
  NotificationReducer,
  TargetJsonReducer,
  EditJsonReducer,
  ImageDiffReducer,
  LocationReducer,
  SummarizationReducer
})

const rootReducer = (history) => (state, action) => {
  return appReducer(history)(state, action);
};

export default rootReducer;

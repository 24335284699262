const initialState = {
  originJson: null,
  editJson: {},
  selectedRowNo: 0,
  lastRead: null
};

const TargetJsonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_NEW_JSON": {
      const { originJson, isCollection } = action.payload
      return {
        ...state,
        originJson: originJson,
        editJson: isCollection ? originJson[0] : originJson,
        selectedRowNo: 0,
        lastRead: Date.now()
      };
    }
    case "CREATE_NEW_JSON": {
      return {
        ...state,
        originJson: {},
        editJson: {},
        lastRead: Date.now()
      };
    }
    case "UPDATE_JSON": {
      return {
        ...state,
        originJson: action.payload.originJson,
      };
    }
    case "SAVE_JSON": {
      return {
        ...state,
        originJson: action.payload.originJson,
        lastRead: Date.now()
      };
    }
    case "SELECT_ROW_JSON": {
      return {
        ...state,
        editJson: action.payload.editJson,
        selectedRowNo: action.payload.selectedRowNo,
      };
    }
    default:
  }

  return state;
}

export default TargetJsonReducer
import { css as emoCSS } from 'emotion'
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green } from '@material-ui/core/colors';

const css = (...args) => ({ className: emoCSS(...args) })

export const RootCss = () => {
    return css({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    })
}

export const LinkCardSearchStyles = theme => ({
    input: {
        marginLeft: theme.spacing(1),
        width: '100%',
    },
    iconButton: {
        padding: 10,
    },
});

export const LinkCardListStyles = theme => ({
    cardListTop: {
        margin: "40px 0 0 0"
    },
    cardList: {
        margin: "84px 0 0 0"
    },
    tagTabBar: {
        position: "fixed",
        zIndex: "999",
        background: "white"
    }
});

export const ProfileStyles = theme => ({
    root: {
        padding: 10,
    },
});

export const LinkCardStyles = theme => ({
    root: {
        padding: 10,
    },
    grow: {
        flexGrow: 1,
    },
    wrapper: {
        margin: 1,
        position: 'relative',
    },
    submit: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});
export const InfoStyles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: 10,
    },
    textLeft: {
        textAlign: 'left',
    },
    paragraph: {
        marginTop: 10,
        marginBottom: 10,
    },
});

export const NotificationStyles = theme => ({
    root: {
        margin: 10,
    },
});

export const SettingsStyles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});

export const SignInStyles = theme => ({
    root: {
        height: '100vh',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        width: '100%',
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginBottom: theme.spacing(1),
    },
});
import _ from 'lodash'
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// Material-UIアイコン取得
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';

// Route関連
import { Link, withRouter } from 'react-router-dom';


// スタイル
const styles = theme => ({
  wrapper: {
    display: 'block',
    width: '100%',
    position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 1000,
    textAlign: 'center',
  },
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  button: {
    maxWidth: '100%', // ボタンが横一杯に広がって欲しくない時はコメントアウト
  },
});




class RouteRelatedBottomNavigation extends React.Component {
  // TODO タブバーの選択状態を変更する
  buttons_info = [
    { label: 'トップページ', icon: <HomeIcon />, to: '/top', value: "top" },
    { label: 'プロフィール', icon: <PersonIcon />, to: '/profile', value: "profile" },
  ];

  buttons = _.map(this.buttons_info, (item, index) => {
    return (
      <BottomNavigationAction
        className={this.props.classes.button}
        component={Link}
        value={item.value}
        label={item.label}
        to={item.to}
        icon={item.icon}
      />
    );
  })
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <BottomNavigation
          value={this.props.location.pathname}
          showLabels
          className={classes.root}
          children={this.buttons}
        />
      </div>
    );
  }
}

RouteRelatedBottomNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(RouteRelatedBottomNavigation));
import { API, API_START, API_END, ACCESS_DENIED, API_ERROR } from "./types";

export function apiAction({
    url = "",
    method = "GET",
    headers = {},
    data = null,
    onSuccess = (data) => { },
    onFailure = (error) => { },
    label = "",
}, getState = null) {
    headers['Content-Type'] = 'application/json'
    headers['Accept-Encoding'] = 'gzip'
    if (getState) {
        // var accessToken = getState().AcountReducer.user.token
        // headers['Authorization'] = `Bearer ${accessToken}`
    }
    return {
        type: API,
        payload: {
            url,
            method,
            headers,
            data,
            onSuccess,
            onFailure,
            label
        }
    };
}

export const apiStart = label => ({
    type: API_START,
    payload: label
});

export const apiData = label => ({
    type: label,
    payload: label
});

export const apiEnd = label => ({
    type: API_END,
    payload: label
});

export const accessDenied = url => ({
    type: ACCESS_DENIED,
    payload: {
        url
    }
});

export const apiError = error => ({
    type: API_ERROR,
    error
});

import React from 'react';

import NotInterested from '@material-ui/icons/NotInterested';
import PersonIcon from '@material-ui/icons/Person';
import PlaceIcon from '@material-ui/icons/Place';
import DescriptionIcon from '@material-ui/icons/Description';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TrainIcon from '@material-ui/icons/Train';

const MenuItems = [
    {
        id: 0,
        head: "JSON",
        subMenuItems: [
            {
                to: "/",
                icon: <PersonIcon />,
                text: "JSON",
            }
        ]
    },
    {
        id: 1,
        head: "CSV",
        subMenuItems: [
            {
                to: "/csv",
                icon: <PlaceIcon />,
                text: "CSV",
            },
            {
                to: "/csv/set-operation",
                icon: <PlaceIcon />,
                text: "CSV集合演算",
            }
        ]
    },
    {
        id: 2,
        head: "TEXT",
        subMenuItems: [
            {
                to: "/text/operation",
                icon: <DescriptionIcon />,
                text: "文字列操作",
            },
            {
                to: "/text/summarization",
                icon: <DescriptionIcon />,
                text: "文書要約",
            },
        ]
    },
    {
        id: 3,
        head: "Image",
        subMenuItems: [
            {
                to: "/image",
                icon: <NotInterested />,
                text: "イメージ加工",
            },
            {
                to: "/image/diff",
                icon: <NotInterested />,
                text: "イメージ差分",
            },
        ]
    },
    {
        id: 4,
        head: "Location",
        subMenuItems: [
            {
                to: "/location/countries",
                icon: <PlaceIcon />,
                text: "国情報",
            },
            {
                to: "/location/list",
                icon: <PlaceIcon />,
                text: "位置情報",
            },
        ]
    },
    {
        id: 5,
        head: "Visualize",
        subMenuItems: [
            {
                to: "/visualize/timeline",
                icon: <NotInterested />,
                text: "タイムライン",
            },
        ]
    },
    {
        id: 6,
        head: "File",
        subMenuItems: [
            {
                to: "/file/extension",
                icon: <NotInterested />,
                text: "ファイル拡張子",
            },
        ]
    },
    {
        id: 7,
        head: "ライブラリ",
        subMenuItems: [
            {
                to: "/libs/camera/quagga",
                icon: <MenuBookIcon />,
                text: "QuaggaJS",
            },
            {
                to: "/libs/react-image-gallery",
                icon: <MenuBookIcon />,
                text: "react-image-gallery",
            },
            {
                to: "/libs/react-window",
                icon: <MenuBookIcon />,
                text: "react-window",
            },
            {
                to: "/libs/react-event-listener",
                icon: <MenuBookIcon />,
                text: "react-event-listener",
            },
            {
                to: "/libs/graphviz/graphviz-react",
                icon: <MenuBookIcon />,
                text: "graphviz-react",
            },
        ]
    },
    {
        id: 8,
        head: "コンポーネントを作ろう",
        subMenuItems: [
            {
                to: "/components/card",
                icon: <MenuBookIcon />,
                text: "カード",
            },
        ]
    },
    {
        id: 9,
        head: "Google",
        subMenuItems: [
            {
                to: "/google/merchant-center/products-specificication",
                icon: <MenuBookIcon />,
                text: "Google Merchant Center プロダクトフィード仕様",
            },
            {
                to: "/google/merchant-center/products-category",
                icon: <MenuBookIcon />,
                text: "Google Merchant Center プロダクトカテゴリ仕様",
            },
        ]
    },
    {
        id: 10,
        head: "Life",
        subMenuItems: [
            {
                to: "/life/can-provide",
                icon: <MenuBookIcon />,
                text: "Baby Can Provide ",
            },
        ]
    },
    {
        id: 11,
        head: "Material UI",
        subMenuItems: [
            {
                to: "/materialui/practice/box",
                icon: <MenuBookIcon />,
                text: "Boxの練習 ",
            },
        ]
    },
]
export default MenuItems
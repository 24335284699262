import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';

import { AdSenseBlock } from '../Utils/AdSenseUtils'
import Grid from '@material-ui/core/Grid';

import WithTracker from '../Templates/WithTracker'
import ErrorBoundary from './ErrorBoundary'
import DataBoundary from './DataBoundary'
import Notification from '../Pages/Notification';
import ResponsiveDrawer from '../Templates/ResponsiveDrawer';
import RouteRelatedBottomNavigation from '../Templates/RouteRelatedBottomNavigation';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  footer: {
    marginTop: 'auto',
  },
});

const WrapMainContent = (WrappedComponent, options = {}) => {
  const HOC = class extends React.Component {
    render() {
      const { classes, options, ...other_props } = this.props;
      return (
        <ErrorBoundary >
          <DataBoundary >
            <Notification />
            <ResponsiveDrawer className="ResponsiveDrawer" >
              <Scrollbars>
                <div className={classes.wrapper}>
                  <React.Suspense fallback={null}>
                    <WrappedComponent {...other_props} {...options} />
                  </React.Suspense>
                  <Grid container>
                    <Grid item xs={6}>
                      <div className={classes.footer}>
                        {<AdSenseBlock id={2} />}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.footer}>
                        {<AdSenseBlock id={3} />}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Scrollbars>
            </ResponsiveDrawer >
            <RouteRelatedBottomNavigation />
          </DataBoundary>
        </ErrorBoundary>
      );
    }
  };
  return (WithTracker(withStyles(styles, { withTheme: true })(HOC)));
};

export default WrapMainContent;
import AdSense from 'react-adsense';
import React from 'react';
const assets = {
    1 : {
        client : 'ca-pub-4890914043397086',
        slot: '7755493527'
    },
    2 : {
        client : 'ca-pub-4890914043397086',
        slot: '2439463952'
    },
    3: {
        client : 'ca-pub-4890914043397086',
        slot: '6461635291'
    }
}
export const AdSenseBlock = ({id}) => {
    const asset = assets[id]
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        return (<div>AdSenseを表示</div>)
    } else {
        return (
            <div className={{height:'100%'}}>
                <AdSense.Google
                  client={asset.client}
                  slot={asset.slot} />
            </div>
        )
    }
}
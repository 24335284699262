import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';

import ListSubheader from '@material-ui/core/ListSubheader';
import MenuIcon from '@material-ui/icons/Menu';

import {
    Drawer,
    AppBar,
    Toolbar,
    List,
    Hidden,
    Divider,
    IconButton,
    Typography
} from '@material-ui/core';

import MenuItems from '../../constants/MenuItems';
import * as UIActions from '../../redux/actions/UIActions';
import * as TargetJsonAction from "../../redux/actions/TargetJsonAction";
import ResponsiveDrawerListItem from '../Molecules/ResponsiveDrawerListItem';

// 設定値
const drawerWidth = 240;
const headerNavigationHeight = 56;
const bottomNavigationHeight = 56;

// スタイル
const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100vh',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        position: 'absolute',
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    toolBar: {
        justifyContent: 'space-between', // 中央寄せのため追加
        minHeight: bottomNavigationHeight,
    },
    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        height: '100vh',
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        paddingTop: `calc(10px + ${headerNavigationHeight}px)`,
        paddingBottom: `calc(10px + ${bottomNavigationHeight}px)`,
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 10,
        },
    },
    // ヘッダーロゴ
    headerLogo: {
        display: 'flex',
        height: 48,
    },
});

class ResponsiveDrawer extends React.Component {

    openDrawer = () => {
        const { actions } = this.props;
        actions.open("DrawerNav")
    }

    closeDrawer = () => {
        const { actions } = this.props;
        actions.close("DrawerNav")
    }

    getToolbar(classes) {
        var title = "Practice YonaYona"
        // TODO タブバーの選択状態を変更する
        return (
            <Toolbar className={classes.toolBar} variant="dense">
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.openDrawer}
                    className={classes.navIconHide} >
                    <MenuIcon />
                </IconButton>
                <Hidden mdUp>
                    <Link to="/" style={{ color: 'white' }}>
                        <Typography
                            style={{ color: "white" }}
                            variant="h5"
                            noWrap
                            gutterBottom>
                            {title}
                        </Typography>
                    </Link>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Link to="/" style={{ color: 'white' }}>
                        <Typography
                            style={{ color: "white" }}
                            variant="h5"
                            noWrap
                            gutterBottom>
                            {title}
                        </Typography>
                    </Link>
                </Hidden>
                <div className={classes.grow} />
            </Toolbar>
        )
    }

    getDesktopDrawer(classes, theme, drawer) {
        const { UIReducer } = this.props;
        return (
            <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={UIReducer.DrawerNav.open}
                onClose={this.closeDrawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}>
                {drawer}
            </Drawer>
        )
    }

    getMobileDrawer(classes, theme, drawer) {
        return (
            <Drawer
                variant="permanent"
                open
                classes={{
                    paper: classes.drawerPaper,
                }}>
                {drawer}
            </Drawer>
        )
    }

    getDrawerItems() {
        return (
            <div>
                <List subheader={<li />}>
                    {
                        MenuItems.map((menuItem) => (
                            <li key={`section-${menuItem.id}`} >
                                <ul >
                                    <ListSubheader>{menuItem.head}</ListSubheader>
                                    {
                                        menuItem.subMenuItems.map((subMenuItem) => (
                                            <ResponsiveDrawerListItem
                                                key={`subMenuItem-${subMenuItem.to}`}
                                                {...subMenuItem}
                                                onClick={this.closeDrawer} />
                                        ))}
                                </ul>
                                <Divider />

                            </li>
                        ))}
                </List>
            </div>
        );
    }

    render() {
        const { classes, theme } = this.props;
        const drawer = this.getDrawerItems()
        return (
            <div className={classes.root}>
                <AppBar className={classes.appBar} position="fixed">
                    {this.getToolbar(classes)}
                </AppBar>
                <Hidden mdUp>
                    {this.getDesktopDrawer(classes, theme, drawer)}
                </Hidden>
                <Hidden smDown implementation="css">
                    {this.getMobileDrawer(classes, theme, drawer)}
                </Hidden>
                <main className={classes.content}>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

ResponsiveDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapState = (state, ownProps) => {
    return {
        UIReducer: state.UIReducer,
    }
};

function mapDispatch(dispatch) {
    return {
        actions: bindActionCreators({
            ...UIActions,
            ...TargetJsonAction,
        }, dispatch),
    };
}

export default connect(mapState, mapDispatch)(withStyles(styles, { withTheme: true })(ResponsiveDrawer));

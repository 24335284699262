const initialState = {
    isOpenJsonEditor: false
};

const EditJsonReducer = (state = initialState, action) => {
    switch (action.type) {
        case "OPEN_JSON_EDITOR_DIALOG": {
            return {
                ...state,
                isOpenJsonEditor: true,
            };
        }
        case "CLOSE_JSON_EDITOR_DIALOG": {
            return {
                ...state,
                isOpenJsonEditor: false,
            };
        }
        default:
    }
    return state;
}
export default EditJsonReducer
import {
    createStore as reduxCreateStore,
    applyMiddleware,
    compose
} from 'redux'

import { createBrowserHistory } from 'history'
import createRootReducer from '../reducers'

import { routerMiddleware } from 'connected-react-router'

import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import sequenceAction from 'redux-sequence-action';
import apiMiddleware from "../../middleware/api";


export const history = createBrowserHistory()

const logger = createLogger({
    diff: true,
    collapsed: true,
})

const rootReducer = createRootReducer(history)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = []
middlewares.push(routerMiddleware(history))
middlewares.push(thunk)
middlewares.push(apiMiddleware)
middlewares.push(sequenceAction)
if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger)
}
export default function createStore(preloadedState) {
    return reduxCreateStore(
        rootReducer,
        preloadedState,
        composeEnhancers(
            applyMiddleware(...middlewares),
        )
    );
}
import { API, API_START } from "../redux/actions/api/types";
import { accessDenied, apiError, apiStart, apiData, apiEnd } from "../redux/actions/api/api";

// inspired by https://leanpub.com/redux-book
import axios from "axios";

const apiMiddleware = ({ dispatch }) => next => action => {
    next(action);
    if (action.type !== API) return;
    const {
        url,
        method,
        data,
        onSuccess,
        onFailure,
        label,
        headers
    } = action.payload;
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
    if (label) {
        dispatch(apiStart(API_START));
        dispatch(apiData(label));
    }
    axios.request({
        url,
        method,
        headers,
        [dataOrParams]: data
    }).then(({ data }) => {
        dispatch(onSuccess(data));
    }).catch(error => {
        dispatch(apiError(error));
        dispatch(onFailure(error));
        if (error.response) {
            if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
                dispatch(accessDenied(url));
            }
        }
    }).finally(() => {
        if (label) {
            dispatch(apiEnd(label));
        }
    });
};

export default apiMiddleware;
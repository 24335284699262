import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import './App.css';

import WrapMainContent from './components/Templates/WrapMainContent'
import WrapSubContent from './components/Templates/WrapSubContent'

const MainContainer = React.lazy(() => import('./components/Pages/JSON/MainContainer'));

const LocationList = React.lazy(() => import('./components/Pages/Location/LocationList'));
const Countries = React.lazy(() => import('./components/Pages/Location/Countries'));

const TextOperation = React.lazy(() => import('./components/Pages/Text/TextOperation'));
const Summarization = React.lazy(() => import('./components/Pages/Text/Summarization'));

const TimeLine = React.lazy(() => import('./components/Pages/TimeLine/TimeLine'));

const WindowOpen = React.lazy(() => import('./components/Pages/Javascript/WindowOpen'));

const CSV = React.lazy(() => import('./components/Pages/CSV/CSV'));
const CSVSetOperation = React.lazy(() => import('./components/Pages/CSV/CSVSetOperation'));

const Image = React.lazy(() => import('./components/Pages/Images/Image'));
const ImageDiff = React.lazy(() => import('./components/Pages/Images/ImageDiff'));

const FileExtension = React.lazy(() => import('./components/Pages/Files/FileExtension'));

const ReactImageGallery = React.lazy(() => import('./components/Pages/Libs/ReactImageGallery'));
const ReactWindow = React.lazy(() => import('./components/Pages/Libs/ReactWindow/ReactWindow'));
const ReactEventListener = React.lazy(() => import('./components/Pages/Libs/ReactEventListener'));
const PracticeReactSizeMe = React.lazy(() => import('./components/Pages/Libs/PracticeReactSizeMe'));
const PracticeReactMarkdown = React.lazy(() => import('./components/Pages/Libs/PracticeReactMarkdown'));
const ReactYoutube = React.lazy(() => import('./components/Pages/Libs/ReactYoutube/ReactYoutube'));
const QrcodeReact = React.lazy(() => import('./components/Pages/Libs/QrcodeReact'));
const PracticeReactInstagramEmbed = React.lazy(() => import('./components/Pages/Libs/PracticeReactInstagramEmbed'));
const ReactMdEditor = React.lazy(() => import('./components/Pages/Libs/ReactMdEditor'));

const CardPage = React.lazy(() => import('./components/Pages/Components/Cards/CardPage'));

const PracticeBooks = React.lazy(() => import('./components/Pages/Google/Books/PracticeBooks'));
const ProductSpecificication = React.lazy(() => import('./components/Pages/Google/MerchantCenter/ProductSpecificication'));
const ProductCategory = React.lazy(() => import('./components/Pages/Google/MerchantCenter/ProductCategory'));

const BabyCanProvide = React.lazy(() => import('./components/Pages/Life/BabyCanProvide'));

const iPhoneHomeIcon = React.lazy(() => import('./components/Pages/Utils/iPhoneHomeIcon'));

const PracticeBox = React.lazy(() => import('./components/Pages/MaterialUI/PracticeBox'));
const PracticeAutocomplete = React.lazy(() => import('./components/Pages/MaterialUI/PracticeAutocomplete'));

const Quagga = React.lazy(() => import('./components/Pages/Libs/Camera/PracticeQuagga'));

const GraphvizReact = React.lazy(() => import('./components/Pages/Libs/Graphviz/GraphvizReact'));

const Apex = React.lazy(() => import('./components/Pages/Games/Apex/Apex'));
const ApexTournament = React.lazy(() => import('./components/Pages/Games/Apex/ApexTournament'));
const ApexMemo = React.lazy(() => import('./components/Pages/Games/Apex/ApexMemo'));
const Manga = React.lazy(() => import('./components/Pages/Manga'));

const WifiShare = React.lazy(() => import('./components/Pages/Services/WifiShare'));
class App extends Component {
    render() {
        return (
            <div className="App">
                <Switch>
                    <Route exact path='/' component={WrapMainContent(MainContainer)} />
                    <Route path='/csv/set-operation' component={WrapMainContent(CSVSetOperation)} />
                    <Route path='/csv' component={WrapMainContent(CSV)} />

                    <Route path='/location/countries' component={WrapMainContent(Countries)} />
                    <Route path='/location/list' component={WrapMainContent(LocationList)} />

                    <Route path='/text/summarization' component={WrapMainContent(Summarization)} />
                    <Route path='/text/operation' component={WrapMainContent(TextOperation)} />
                    <Route path='/visualize/timeline' component={WrapMainContent(TimeLine)} />
                    <Route path='/image' component={WrapMainContent(Image)} />
                    <Route path='/image/diff' component={WrapMainContent(ImageDiff)} />
                    <Route path='/file/extension' component={WrapMainContent(FileExtension)} />

                    <Route path='/javascript/window-open' component={WrapMainContent(WindowOpen)} />

                    <Route path='/libs/react-image-gallery' component={WrapMainContent(ReactImageGallery)} />
                    <Route path='/libs/react-window' component={WrapMainContent(ReactWindow)} />
                    <Route path='/libs/react-event-listener' component={WrapMainContent(ReactEventListener)} />
                    <Route path='/libs/camera/quagga' component={WrapMainContent(Quagga)} />
                    <Route path='/libs/graphviz/graphviz-react' component={WrapMainContent(GraphvizReact)} />
                    <Route path='/libs/react-youtube' component={WrapMainContent(ReactYoutube)} />
                    <Route path='/libs/react-sizeme' component={WrapMainContent(PracticeReactSizeMe)} />
                    <Route path='/libs/react-markdown' component={WrapMainContent(PracticeReactMarkdown)} />
                    <Route path='/libs/qrcode-react' component={WrapMainContent(QrcodeReact)} />
                    <Route path='/libs/react-instagram-embed' component={WrapMainContent(PracticeReactInstagramEmbed)} />
                    <Route path='/libs/react-md-editor' component={WrapMainContent(ReactMdEditor)} />

                    <Route path='/components/card' component={WrapMainContent(CardPage)} />

                    <Route path='/google/merchant-center/products-specificication' component={WrapMainContent(ProductSpecificication)} />
                    <Route path='/google/merchant-center/products-category' component={WrapMainContent(ProductCategory)} />
                    <Route path='/google/books/practice' component={WrapSubContent(PracticeBooks)} />

                    {/* <Route path='/utils/iphone-home-icon' component={WrapMainContent(iPhoneHomeIcon)} /> */}

                    <Route path='/materialui/practice/box' component={WrapMainContent(PracticeBox)} />
                    <Route path='/materialui/practice/autocomplete' component={WrapMainContent(PracticeAutocomplete)} />


                    <Route path='/life/can-provide' component={WrapMainContent(BabyCanProvide)} />

                    <Route path='/games/apex' component={WrapSubContent(Apex)} />
                    <Route path='/games/apex-memo/:name' component={WrapSubContent(ApexMemo)} />
                    <Route path='/games/apex-tournament/:name' component={WrapSubContent(ApexTournament)} />

                    <Route path='/manga/:name' component={WrapSubContent(Manga)} />

                    <Route path='/services/wifi-share' component={WrapSubContent(WifiShare)} />

                </Switch>
            </div>
        );
    }
}

export default App;

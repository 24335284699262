import * as ApiActions from './api/ApiActions'
import { ApiUrls } from '../../utils/ApiClient'

export const SEARCH_LOCATION_LIST_REQUEST = Symbol('SEARCH_LOCATION_LIST_REQUEST');
export const SEARCH_LOCATION_LIST_SUCCESS = Symbol('SEARCH_LOCATION_LIST_SUCCESS');
export const SEARCH_LOCATION_LIST_FAILURE = Symbol('SEARCH_LOCATION_LIST_FAILURE');

export const searchLocationList = (searchKeyword) => {
  const url = ApiUrls.SEARCH_ADDRESS
  const data = {
    search_keyword: searchKeyword
  }
  const types = {
    request: SEARCH_LOCATION_LIST_REQUEST,
    success: SEARCH_LOCATION_LIST_SUCCESS,
    failure: SEARCH_LOCATION_LIST_FAILURE,
  }
  return ApiActions.get(url, data, types)
}

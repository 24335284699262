export const ApiUrls = {
    SIGN_UP: `${process.env.REACT_APP_API_ENDPOINT}/api/register`,
    SIGN_IN: `${process.env.REACT_APP_API_ENDPOINT}/api/login`,
    FORGOT_PASSWORD: `${process.env.REACT_APP_API_ENDPOINT}/api/password/email`,
    RESET_PASSWORD: `${process.env.REACT_APP_API_ENDPOINT}/api/password/reset`,
    GET_SCRAPE: `${process.env.REACT_APP_API_ENDPOINT}/api/scrape`,
    GET_TOP_LINK_CARD_LIST: `${process.env.REACT_APP_API_ENDPOINT}/api/top_link_cards`,
    GET_LINK_CARD_LIST: `${process.env.REACT_APP_API_ENDPOINT}/api/link_cards`,
    SEARCH_LINK_CARD_LIST: `${process.env.REACT_APP_API_ENDPOINT}/api/link_cards/search`,
    SEARCH_NEAR_LINK_CARD_LIST: `${process.env.REACT_APP_API_ENDPOINT}/api/link_cards/search_near`,
    GET_USER_INFO: `${process.env.REACT_APP_API_ENDPOINT}/api/me`,
    LIKED_CARDS: `${process.env.REACT_APP_API_ENDPOINT}/api/liked_cards`,
    LIKED_TAGS: `${process.env.REACT_APP_API_ENDPOINT}/api/liked_tags`,
    SHOW_LINK_CARD: (id) => `${process.env.REACT_APP_API_ENDPOINT}/api/link_cards/${id}`,
    CREATE_LINK_CARD: `${process.env.REACT_APP_API_ENDPOINT}/api/link_cards`,
    UPDATE_LINK_CARD: (id) => `${process.env.REACT_APP_API_ENDPOINT}/api/link_cards/${id}`,
    CREATE_TAG: `${process.env.REACT_APP_API_ENDPOINT}/api/tags`,
    TAG_LIST: `${process.env.REACT_APP_API_ENDPOINT}/api/tag_types`,
    INSIGHT: `${process.env.REACT_APP_API_ENDPOINT}/api/insight`,
    SEARCH_ADDRESS: `${process.env.REACT_APP_API_ENDPOINT}/api/geocoding/search_address`,
    SUMMARIZATION: `${process.env.REACT_APP_API_ENDPOINT}/api/summarization`,
}
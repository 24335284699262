// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import { Provider } from "react-redux";

// import store from "./js/store";

// ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();


import createStore, { history } from './redux/store/index'

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import './index.css';
import App from './App';
import serviceWorker from './serviceWorker';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';

export const store = createStore({});
// Material-UIテーマカスタマイズ
const theme = createMuiTheme({
  palette: {},
});


ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
    <MuiThemeProvider theme={theme} >
        <Router>
        <App />
        </Router>
    </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
  , document.getElementById('root'));
serviceWorker();
const initialState = {
  firstImage: null,
  secondImage: null,
  diffImage: null
}
const ImageDiffReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IMAGE_DATA": {
      var newState = {
        ...state,
        [action.payload.name]: action.payload.image_data
      }
      if (state.firstImage && state.secondImage) {
        newState.diffImage = null
      }
      return newState;
    }
    default:
      return initialState
  }
}

export default ImageDiffReducer

export const OPEN_DIALOG = Symbol('OPEN_DIALOG');
export const CLOSE_DIALOG = Symbol('CLOSE_DIALOG');
export const SET_NOTIFICATION = Symbol('SET_NOTIFICATION');
export const CLOSE_NOTIFICATION = Symbol('CLOSE_NOTIFICATION');

export const open = (dialogName) => ({
  type: OPEN_DIALOG,
  dialogName: dialogName
});

export const close = (dialogName) => ({
  type: CLOSE_DIALOG,
  dialogName: dialogName
});

// Notification操作
export const setNotification = (variant, message) => ({
  type: SET_NOTIFICATION,
  variant: variant,
  message: message,
});

export const closeNotification = (variant, message) => ({
  type: CLOSE_NOTIFICATION,
});

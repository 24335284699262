import React from 'react';
import {
    IconButton,
} from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import MenuIcon from '@material-ui/icons/Menu';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';

import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import GpsOffIcon from '@material-ui/icons/GpsOff';

export function LikedButton(props) {
    const { isLiked } = props
    const { onLiked, onUnLiked } = props
    return (
        <IconButton
            value="check"
            onClick={() => {
                if (isLiked) {
                    onUnLiked && onUnLiked()
                } else {
                    onLiked && onLiked()
                }
            }}
        >
            <FavoriteIcon color={isLiked ? 'secondary' : 'disabled'} />
        </IconButton>
    )
}
export function GpsButton(props) {
    const { isPositive } = props
    const { onChange } = props
    return (
        <IconButton
            value="check"
            onClick={(e) => { onChange(!isPositive) }}
        >
            <GpsFixedIcon color={isPositive ? 'secondary' : 'disabled'} />
        </IconButton>
    )
}

export function ToggleButton(props) {
    const { isPositive, icon } = props
    const { onChange } = props
    return (
        <IconButton
            value="check"
            onClick={() => { onChange(isPositive) }}
        >
            <icon color={isPositive ? 'secondary' : 'disabled'} />
        </IconButton>
    )
}

export function DrawerButton(props) {
    return (
        <IconButton
            color="inherit"
            aria-label="Open drawer"
            {...props}>
            <MenuIcon />
        </IconButton>
    )
}

export function DeleteButton(props) {
    return (
        <IconButton
            aria-label="Icon"
            {...props}>
            <DeleteIcon />
        </IconButton>
    )
}

export function CloseButton(props) {
    return (
        <IconButton
            aria-label="Close"
            color="primary"
            {...props}>
            <CloseIcon {...props.icon} />
        </IconButton>
    )
}

export function ShareButton(props) {
    return (
        <IconButton
            aria-label="Share"
            {...props}>
            <ShareIcon {...props.icon} />
        </IconButton>
    )
}

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@material-ui/core';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({ error, errorInfo })
        if (process.env.NODE_ENV === 'production') {
            // this.props.actions.clearState()
        }
        // You can also log error messages to an error reporting service here
    }
    crearState = () => this.props.actions.clearState()

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    {
                        process.env.NODE_ENV === 'development' &&
                        <div>
                            <Button
                                fullWidth
                                variant="contained"
                                color='primary'
                                style={{ margin: 2 }}
                                onClick={ this.crearState }>
                                ストア削除
                            </Button>
                            <details style={{ whiteSpace: 'pre-wrap' }}>
                                {this.state.error && this.state.error.toString()}
                                < br />
                                {this.state.errorInfo.componentStack}
                                < br />
                            </details>
                        </div>
                    }
                </div>
            );
        }
        // Normally, just render children
        return React.Children.map(this.props.children, child =>
            React.cloneElement(child, { float: true })
          );
    }
}

const mapState = (state, ownProps) => {
    return {}
};

function mapDispatch(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

export default connect(mapState, mapDispatch)(ErrorBoundary);
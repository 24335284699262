import { apiAction } from '../api/api'

export const success = (type) => (response) => ({
  type: type,
  response: response,
  receivedAt: Date.now()
})

export const failure = (type) => (response) => ({
  type: type,
  response: response,
  receivedAt: Date.now()
})

export const request = (method, url, data, types, headers={} ,getState=null) => {
  return apiAction({
    method: method,
    url: url,
    headers: headers,
    data: data,
    onSuccess: success(types.success),
    onFailure: failure(types.failure),
    label: types.request
  }, getState);
}

export const get = (url, data, types, headers={}) => {
  return (dispatch, getState) => dispatch(request("GET" ,url, data, types, headers, getState))
}

export const post = (url, data, types, headers={}) => {
  return (dispatch, getState) => dispatch(request("POST" ,url, data, types, headers, getState))
}

export const put = (url, data, types, headers={}) => {
  return (dispatch, getState) => dispatch(request("PUT" ,url, data, types, headers, getState))
}

export const del = (url, data, types, headers={}) => {
  return (dispatch, getState) => dispatch(request("DELETE" ,url, data, types, headers, getState))
}
import * as TextActions from '../actions/TextActions';

const initialState = {
  url: "",
  document: "",
  summarized: "",
};

const SummarizationReducer = (state = initialState, action) => {

  switch (action.type) {
    case TextActions.CHANGE_TEXT: {
      const {name, value} = action
      return {
        ...state,
        [name] : value
      }
    }
    case TextActions.SUMMARIZATION_REQUEST: {
      return {
        ...state,
        summarized: "",
        http: {
          isFetching: true,
          response: {},
          error: {},
          lastUpdated: null
        },
      }
    }
    case TextActions.SUMMARIZATION_SUCCESS: {
      const { url, document, summarized } = action.response
      return {
        ...state,
        url: url,
        document: document,
        summarized: summarized,
        http: {
          isFetching: false,
          response: action.response,
          error: {},
          lastUpdated: action.receivedAt
        }
      }
    }
    case TextActions.SUMMARIZATION_FAILURE: {
      return {
        ...state,
        summarized: "",
        http: {
          isFetching: false,
          response: {},
          error: action.response,
          lastUpdated: action.receivedAt
        }
      }
    }
    default:
      return state;
  }
};

export default SummarizationReducer;